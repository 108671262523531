import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import BackgroundImage from "../components/index/background";

const IndexPage = () => (
  <Layout showFooter={false} activePage="home">
    <SEO title="Strona główna" />
    <BackgroundImage>
      <Segment
        style={{
          background: "none",
          margin: "0 0 0 0",
          height: "calc(100vh - 73px)",
        }}
      >
        <Container text>
          <Header
            as="h1"
            content="POMOC IT NA TELEFON"
            inverted
            style={{
              fontSize: "3em",
              fontWeight: "normal",
              margin: "40% 0 0 0",
              border: 0,
            }}
          />
          <Header
            as="h2"
            inverted
            content="Usługi Doradcy Informatycznego dla klientów Medicover Benefits"
            style={{
              fontSize: "1em",
              fontWeight: "normal",
              marginTop: "1.5em",
            }}
          />
          <a
            href="https://medicover24.doradcainformatyczny.pl"
            className="ui orange button"
          >
            <Icon name="external" />
            Skorzystaj z usługi
          </a>
        </Container>
      </Segment>
    </BackgroundImage>
  </Layout>
);

export default IndexPage;
